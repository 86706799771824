






























import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { formatAmount } from '@/utils/billingUtils'

@Component
export default class SettlementCalculationLines extends Vue {
  @Prop()
  private data

  private get tableBodies() {
    if (!this.data || !this.data.calculationLines) {
      return []
    }
    const chunks = []
    let chunk = []
    let chunkId = 1
    for (let i = 0; i < this.data.calculationLines.length; i++) {
      const line = this.data.calculationLines[i]
      chunk.push(line)
      if (line.isSum) {
        chunks.push({
          id: chunkId,
          final: false,
          lines: chunk,
        })
        chunk = []
        chunkId++
      }
    }
    if (chunk.length > 0) {
      chunks.push({
        id: chunkId,
        final: false,
        lines: chunk,
      })
    }
    if (chunks.length > 0) {
      chunks[chunks.length - 1].final = true
    }
    return chunks
  }

  private formatAmount(amount: string, isSum: boolean): string {
    if (!isSum && /^0(\.0+)?$/.test(amount)) {
      return '-'
    }
    return formatAmount(amount)
  }
}
