





























import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import SettlementHeaderField from '@/components/sysop/billing/Settlement/SettlementHeaderField.vue'

@Component({
  components: {
    SettlementHeaderField,
  },
})
export default class SettlementHeader extends Vue {
  @Prop()
  private data

  private get date() {
    if (!this.data.date) {
      return ''
    }
    return (this.data.date || '').split('T')[0] || ''
  }
}
