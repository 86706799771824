







import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class SettlementHeaderField extends Vue {
  @Prop()
  private label: string

  @Prop()
  private value
}
