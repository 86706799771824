








































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { formatAmount } from '@/utils/billingUtils'

@Component
export default class SettlementInvoiceLines extends Vue {
  @Prop()
  private data

  private get lines() {
    if (!this.data || !this.data.invoiceLines) {
      return []
    }
    return this.data.invoiceLines
  }

  private get sum() {
    return this.data?.amountSettled || ''
  }

  private formatAmount(amount: string): string {
    return formatAmount(amount)
  }

  private getSiteDescription(line): string {
    if (!line) {
      return ''
    }
    return line.invoiceLine?.site?.name + ' (' + line.invoiceLine?.siteId + ')'
  }
}
