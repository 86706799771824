export function getStatusClass(item: Record<string, unknown>): string {
  if ('' + item.invoiceCreatedAmount === '' + item.totalAmount) {
    return 'status-ok'
  } else {
    return 'status-error'
  }
}

export function getSentStatusClass(item: Record<string, unknown>): string {
  if ('' + item.invoiceSentAmount === '' + item.totalAmount) {
    return 'status-ok'
  } else {
    return 'status-error'
  }
}

// todo: optional decimals (depending on view), thousands separator
export function formatAmount(amount: string | number): string {
  const rounded = Math.round(parseFloat('' + amount) * 100) / 100
  return rounded.toFixed(2)
}

export function getSiteName(siteId: number, sites: Array<Record<string, unknown>>): string {
  if (!siteId) {
    return ''
  }
  for (let i = 0; i < sites.length; i++) {
    if (sites[i].id === siteId) {
      return (sites[i].name as string) + ' (' + siteId + ')'
    }
  }
  return '(' + siteId + ')'
}

export function getPayerName(payerId: number, payers: Array<Record<string, unknown>>): string {
  if (!payerId) {
    return ''
  }
  for (let i = 0; i < payers.length; i++) {
    if (payers[i].id === payerId) {
      return (payers[i].name as string) + ' (' + payerId + ')'
    }
  }
  return '(' + payerId + ')'
}

export function getProductName(productId: number, products: Array<Record<string, unknown>>): string {
  if (!productId) {
    return ''
  }
  for (let i = 0; i < products.length; i++) {
    if (products[i].id === productId) {
      return (products[i].name as string) + ' (' + productId + ')'
    }
  }
  return '(' + productId + ')'
}
